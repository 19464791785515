export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.server) {
    const isSettingsPage =
      /^dashboard-settings(-|$)/.test(to.name) &&
      /^dashboard-settings(-|$)/.test(from.name);

    if (from.name !== to.name && isSettingsPage) {
      to.meta.pageTransition = false;
    }
  }
});
